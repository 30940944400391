

#home-container {
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}
#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content : space-around;
    text-align: center;
    width:50%;
}

#home .film-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
#home .review-bar {
    width:90%;
}
#home .film-name {
    margin:1rem 0rem 1rem 0rem;
}

#home .film-review {
    padding:0px;
    width:100%;
}

#home .film-img {
    width:20rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 480px) {

    #home {
        width:95%;
    }

}

.flex-column {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
}