

#home .photo-img {
    width: 50rem;
    margin-bottom: 1rem;
    max-width: 100%;
}

#home .dropdown {
    margin-bottom:2rem;
    width: 30%;
}

@media screen and (max-width: 480px) {

    #home .dropdown {
        width:95%;
    }

}